<template>
    <field-row
        :title="title"
        :tooltip="tooltip"
        :line-number="lineNumber"
        :hide-line-number="hideLineNumber"
    >
        <v-select
            :items="items"
            :value="$attrs.value"
            @change="$emit('input', $event)"
            dense
            required
            attach
        />
    </field-row>
</template>

<script>
import FieldRow from "components/create_store/fields/FieldRow";

export default {
    name: "SelectRow",
    components: {
        FieldRow,
    },
    props: {
        lineNumber: {
            type: Number,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
        tooltip: {
            type: String,
            required: false,
        },
        inputLabel: {
            type: String,
            required: false,
        },
        items: {
            type: Array,
            required: true,
        },
        hideLineNumber: {
            type: Boolean,
            required: false,
        }
    },
}
</script>

<style scoped>

</style>
