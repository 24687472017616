var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("div", { staticClass: "line-item--label" }, [
              _vm._v(" Popup title "),
            ]),
          ]),
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: { label: "Popup title", dense: "" },
                model: {
                  value: _vm.value.about.popupTitle,
                  callback: function ($$v) {
                    _vm.$set(_vm.value.about, "popupTitle", $$v)
                  },
                  expression: "value.about.popupTitle",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("div", { staticClass: "line-item--label" }, [
              _vm._v(" Popup copy "),
            ]),
          ]),
          _c(
            "v-col",
            [
              _c("v-textarea", {
                attrs: { label: "Popup title", dense: "" },
                model: {
                  value: _vm.value.about.popupCopy,
                  callback: function ($$v) {
                    _vm.$set(_vm.value.about, "popupCopy", $$v)
                  },
                  expression: "value.about.popupCopy",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }