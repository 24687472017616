<template>
    <v-row>
        <v-col v-bind="lineNumberProps">
            <line-number :label="baseIndex + index"/>
        </v-col>
        <v-col>
            <v-row>
                <v-col>
                    <div class="line-item--label">
                        Secondary tile
                    </div>
                </v-col>
                <v-col>
                    <div class="text-end">
                        Reorder Tile
                        <v-btn v-if="!isLastTile" icon @click="reorderTileDownward()">
                            <v-icon color="#93bd20">arrow_downward</v-icon>
                        </v-btn>
                        <v-btn v-if="index !== 0 " icon @click="reorderTileUpward()">
                            <v-icon color="#93bd20">arrow_upward</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <hr>
            <secondary-tile-options-editor
                v-model="value"
            />
            <hr v-if="value.type !== 'tab'">
            <survey-editor
                v-if="value.type === 'survey'"
                v-model="value"
            />
            <nutritional-information-editor
                v-if="value.type === 'nutritionalInformation'"
                v-model="value"
            />
            <about-us-editor
                v-if="value.type === 'aboutUs'"
                v-model="value"
            />
            <caloric-information-editor
                v-if="value.type === 'caloricInformation'"
                v-model="value"
            />
            <hr>
            <v-row>
                <v-col>
                    <v-btn
                        color="error"
                        outlined
                        @click="discardTile()"
                    >
                        Discard this tile
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import SecondaryTileOptionsEditor from "components/create_store/tile_editor/secondary_tile_options_editor";
import LineNumber from "components/LineNumber";
import TextTooltip from "components/text_tooltip";
import SurveyEditor from "./survey_editor";
import NutritionalInformationEditor from "./nutritional_information_editor";
import AboutUsEditor from "./about_us_editor";
import CaloricInformationEditor from "./caloric_information_editor";

export default {
    name: "TileEditor",
    components: {
        SecondaryTileOptionsEditor,
        LineNumber,
        TextTooltip,
        SurveyEditor,
        NutritionalInformationEditor,
        AboutUsEditor,
        CaloricInformationEditor,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        baseIndex: {
            type: Number,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        isLastTile: {
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {
            lineNumberProps: {
                md: '1',
                sm: '2',
                lg: '1',
            },

        };
    },
    methods: {
        discardTile() {
            this.$emit('discard');
        },
        reorderTileUpward() {
            this.$emit('reorderUpward');
        },
        reorderTileDownward() {
            this.$emit('reorderDownward');
        }
    }
}
</script>

<style scoped lang="scss">
@import "/src/pages/create_store/wizard/section";
</style>
