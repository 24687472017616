var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "field-row",
    {
      attrs: {
        title: _vm.title,
        tooltip: _vm.tooltip,
        "line-number": _vm.lineNumber,
        "hide-line-number": _vm.hideLineNumber,
      },
    },
    [
      _c("v-select", {
        attrs: {
          items: _vm.items,
          value: _vm.$attrs.value,
          dense: "",
          required: "",
          attach: "",
        },
        on: {
          change: function ($event) {
            return _vm.$emit("input", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }