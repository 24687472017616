var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("color-input-row", {
        attrs: { "line-number": "1", title: "Background color" },
        model: {
          value: _vm.value.backgroundColor,
          callback: function ($$v) {
            _vm.$set(_vm.value, "backgroundColor", $$v)
          },
          expression: "value.backgroundColor",
        },
      }),
      _c("v-divider"),
      _c("color-input-row", {
        attrs: { "line-number": "2", title: "Spinner" },
        model: {
          value: _vm.value.spinner,
          callback: function ($$v) {
            _vm.$set(_vm.value, "spinner", $$v)
          },
          expression: "value.spinner",
        },
      }),
      _c("v-divider"),
      _c("color-input-row", {
        attrs: { "line-number": "3", title: "Language icon" },
        model: {
          value: _vm.value.languageIcon,
          callback: function ($$v) {
            _vm.$set(_vm.value, "languageIcon", $$v)
          },
          expression: "value.languageIcon",
        },
      }),
      _c("v-divider"),
      _c("file-input-row", {
        attrs: {
          "line-number": "5",
          title: "Logo",
          "input-label": "Logo (.png or .svg preferred)",
          required: "",
        },
        model: {
          value: _vm.value.siteLogo,
          callback: function ($$v) {
            _vm.$set(_vm.value, "siteLogo", $$v)
          },
          expression: "value.siteLogo",
        },
      }),
      _c("v-divider"),
      _c("field-row", { attrs: { "line-number": "6", title: "Primary tile" } }),
      _c("v-divider", { staticClass: "ml-16" }),
      _c("select-row", {
        attrs: { items: _vm.tileTypeOptions, title: "Type" },
        model: {
          value: _vm.value.primaryTile.type,
          callback: function ($$v) {
            _vm.$set(_vm.value.primaryTile, "type", $$v)
          },
          expression: "value.primaryTile.type",
        },
      }),
      _c("v-divider", { staticClass: "ml-16" }),
      _c("text-field-row", {
        attrs: { title: "Label" },
        model: {
          value: _vm.value.primaryTile.label,
          callback: function ($$v) {
            _vm.$set(_vm.value.primaryTile, "label", $$v)
          },
          expression: "value.primaryTile.label",
        },
      }),
      _c("color-input-row", {
        attrs: { title: "Label Color" },
        model: {
          value: _vm.value.primaryTile.labelColor,
          callback: function ($$v) {
            _vm.$set(_vm.value.primaryTile, "labelColor", $$v)
          },
          expression: "value.primaryTile.labelColor",
        },
      }),
      _c("v-divider", { staticClass: "ml-16" }),
      _c("select-row", {
        attrs: { items: _vm.iconTypes, title: "Icon" },
        model: {
          value: _vm.value.primaryTile.icon.type,
          callback: function ($$v) {
            _vm.$set(_vm.value.primaryTile.icon, "type", $$v)
          },
          expression: "value.primaryTile.icon.type",
        },
      }),
      _vm.value.primaryTile.icon.type === "color"
        ? _c("color-input-row", {
            attrs: { title: "Color" },
            model: {
              value: _vm.value.primaryTile.icon.color,
              callback: function ($$v) {
                _vm.$set(_vm.value.primaryTile.icon, "color", $$v)
              },
              expression: "value.primaryTile.icon.color",
            },
          })
        : _vm._e(),
      _vm.value.primaryTile.icon.type === "file"
        ? _c("file-input-row", {
            attrs: { title: "File", "input-label": "Upload", required: "" },
            model: {
              value: _vm.value.primaryTile.icon.file,
              callback: function ($$v) {
                _vm.$set(_vm.value.primaryTile.icon, "file", $$v)
              },
              expression: "value.primaryTile.icon.file",
            },
          })
        : _vm._e(),
      _c("v-divider", { staticClass: "ml-16" }),
      _c("color-input-row", {
        attrs: { title: "Tile color" },
        model: {
          value: _vm.value.primaryTile.color,
          callback: function ($$v) {
            _vm.$set(_vm.value.primaryTile, "color", $$v)
          },
          expression: "value.primaryTile.color",
        },
      }),
      _c("select-row", {
        attrs: { items: _vm.tileImageTypes, title: "Tile image" },
        model: {
          value: _vm.value.primaryTile.tileImage.type,
          callback: function ($$v) {
            _vm.$set(_vm.value.primaryTile.tileImage, "type", $$v)
          },
          expression: "value.primaryTile.tileImage.type",
        },
      }),
      _vm.value.primaryTile.tileImage.type === "file"
        ? _c("file-input-row", {
            attrs: { title: "Upload image file" },
            model: {
              value: _vm.value.primaryTile.tileImage.file,
              callback: function ($$v) {
                _vm.$set(_vm.value.primaryTile.tileImage, "file", $$v)
              },
              expression: "value.primaryTile.tileImage.file",
            },
          })
        : _vm._e(),
      _c("v-divider", { staticClass: "ml-16" }),
      _c("select-row", {
        attrs: {
          items: _vm.tileOverlayColorTypes,
          title: "Tile overlay color (optional)",
        },
        model: {
          value: _vm.value.primaryTile.overlayColor.type,
          callback: function ($$v) {
            _vm.$set(_vm.value.primaryTile.overlayColor, "type", $$v)
          },
          expression: "value.primaryTile.overlayColor.type",
        },
      }),
      _vm.value.primaryTile.overlayColor.type === "color"
        ? _c("color-input-row", {
            attrs: { title: "Overlay Color" },
            model: {
              value: _vm.value.primaryTile.overlayColor.color,
              callback: function ($$v) {
                _vm.$set(_vm.value.primaryTile.overlayColor, "color", $$v)
              },
              expression: "value.primaryTile.overlayColor.color",
            },
          })
        : _vm._e(),
      _c("v-divider"),
      _vm._l(_vm.value.secondaryTiles, function (tile, i) {
        return _c("tile-editor", {
          attrs: {
            value: tile,
            "base-index": 7,
            index: i,
            "is-last-tile": i === _vm.value.secondaryTiles.length - 1,
          },
          on: {
            discard: function ($event) {
              return _vm.discardTile(i)
            },
            reorderUpward: function ($event) {
              return _vm.reorderTileUpward(i)
            },
            reorderDownward: function ($event) {
              return _vm.reorderTileDownward(i)
            },
          },
        })
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "text-center" },
            [
              _c(
                "v-btn",
                {
                  on: {
                    click: function ($event) {
                      return _vm.addSecondaryTile()
                    },
                  },
                },
                [_vm._v(" Add a secondary tile ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }