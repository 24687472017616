<template>
    <div>
        <v-row>
            <v-col>
                <div class="line-item--label">
                    Popup title
                </div>
            </v-col>
            <v-col>
                <v-text-field
                    v-model="value.nutritionalInformation.popupTitle"
                    label="Popup title"
                    dense
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="line-item--label">
                    Popup copy
                </div>
            </v-col>
            <v-col>
                <v-textarea
                    v-model="value.nutritionalInformation.popupCopy"
                    label="Popup title"
                    dense
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "NutritionalInformationEditor",
    props: {
        value: {
            type: Object,
            required: true,
        }
    },
}
</script>

<style scoped lang="scss">
@import "/src/pages/create_store/wizard/section";
</style>
