var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c("v-col", [
        _c("div", { staticClass: "line-item--label" }, [
          _vm._v(" Caloric information "),
        ]),
      ]),
      _c(
        "v-col",
        [
          _c("v-textarea", {
            attrs: { label: "Caloric information", dense: "" },
            model: {
              value: _vm.value.caloricInformation,
              callback: function ($$v) {
                _vm.$set(_vm.value, "caloricInformation", $$v)
              },
              expression: "value.caloricInformation",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }