<template>
    <div>
        <v-row>
            <v-col>
                <div class="line-item--label">
                    Survey Icon
                </div>
            </v-col>
            <v-col>
                <v-radio-group
                    v-model="value.survey.iconType"
                    row
                    dense
                >
                    <v-radio value="stars">
                        <template slot="label">
                            Stars <v-icon>star</v-icon>
                        </template>
                    </v-radio>
                    <v-radio value="smiles">
                        <template slot="label">
                            Smiles <v-icon>face</v-icon>
                        </template>
                    </v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <hr>
        <v-row v-for="(_, i) of value.survey.questions">
            <v-col>
                <div class="line-item--label">
                    Question #{{ i+1 }}
                </div>
            </v-col>
            <v-col>
                <v-text-field
                    v-model="value.survey.questions[i]"
                    :label="'Question #' + (i+1)"
                    dense
                />
            </v-col>
            <v-col md="1">
                <v-btn v-if="i !== 0" icon @click="deleteQuestion(i)">
                    <v-icon>delete</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn
                    outlined
                    color="primary"
                    @click="addQuestion()"
                >
                    Add another question
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "SurveyEditor",
    props: {
        value: {
            type: Object,
            required: true,
        }
    },
    methods: {
        addQuestion() {
            this.value.survey.questions.push('');
        },
        deleteQuestion(index) {
            this.value.survey.questions.splice(index, 1);
        },
    }
}
</script>

<style scoped lang="scss">
@import "/src/pages/create_store/wizard/section";
</style>
