var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        _vm._b({}, "v-col", _vm.lineNumberProps, false),
        [_c("line-number", { attrs: { label: _vm.baseIndex + _vm.index } })],
        1
      ),
      _c(
        "v-col",
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c("div", { staticClass: "line-item--label" }, [
                  _vm._v(" Secondary tile "),
                ]),
              ]),
              _c("v-col", [
                _c(
                  "div",
                  { staticClass: "text-end" },
                  [
                    _vm._v(" Reorder Tile "),
                    !_vm.isLastTile
                      ? _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                return _vm.reorderTileDownward()
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { color: "#93bd20" } }, [
                              _vm._v("arrow_downward"),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.index !== 0
                      ? _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function ($event) {
                                return _vm.reorderTileUpward()
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { color: "#93bd20" } }, [
                              _vm._v("arrow_upward"),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c("hr"),
          _c("secondary-tile-options-editor", {
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
          _vm.value.type !== "tab" ? _c("hr") : _vm._e(),
          _vm.value.type === "survey"
            ? _c("survey-editor", {
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              })
            : _vm._e(),
          _vm.value.type === "nutritionalInformation"
            ? _c("nutritional-information-editor", {
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              })
            : _vm._e(),
          _vm.value.type === "aboutUs"
            ? _c("about-us-editor", {
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              })
            : _vm._e(),
          _vm.value.type === "caloricInformation"
            ? _c("caloric-information-editor", {
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              })
            : _vm._e(),
          _c("hr"),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", outlined: "" },
                      on: {
                        click: function ($event) {
                          return _vm.discardTile()
                        },
                      },
                    },
                    [_vm._v(" Discard this tile ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }