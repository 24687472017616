<template>
<div>
    <v-row>
        <v-col>
            <div class="line-item--label">
                Label
            </div>
        </v-col>
        <v-col>
            <v-text-field
                label="Label"
                v-model="value.label"
                dense
            />
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <div class="line-item--label">
                Label Color
            </div>
        </v-col>
        <v-col>
            <v-color-input
                v-model="value.labelColor"
                :label="value.labelColor"
                mode="hex"
                elevation="2"
                class="color-input"
                no-alpha
                hide-mode-switch
            />
        </v-col>
    </v-row>
    <hr>
    <v-row>
        <v-col>
            <div class="line-item--label">
                Type
            </div>
        </v-col>
        <v-col>
            <v-select
                :items="tileTypeOptions"
                v-model="value.type"
                dense
                required
                attach
            />
        </v-col>
    </v-row>
    <hr>
    <v-row>
        <v-col>
            <div class="line-item--label">
                Icon
            </div>
        </v-col>
        <v-col>
            <v-row>
                <v-col>
                    <v-select
                        :items="iconTypes"
                        v-model="value.icon.type"
                        dense
                        required
                        attach
                    />
                </v-col>
            </v-row>
        </v-col>
    </v-row>
    <v-row v-if="value.icon.type === 'color'">
        <v-col>
            <div class="line-item--label">
                Color
            </div>
        </v-col>
        <v-col>
            <v-color-input
                v-model="value.icon.color"
                :label="value.icon.color"
                mode="hex"
                elevation="2"
                class="color-input"
                no-alpha
                hide-mode-switch
            />
        </v-col>
    </v-row>
    <v-row v-if="value.icon.type === 'file'">
        <v-col>
            <div class="line-item--label">
                File
            </div>
        </v-col>
        <v-col>
            <v-file-input
                v-model="value.icon.file"
                label="Upload"
                clearable
                dense
                required
                :rules="[v => !!v || 'File is required!']"
            />
        </v-col>
    </v-row>
    <hr />
    <v-row>
        <v-col>
            <div class="line-item--label">
                Tile color
            </div>
        </v-col>
        <v-col>
            <v-color-input
                v-model="value.color"
                :label="value.color"
                mode="hex"
                elevation="2"
                class="color-input"
                no-alpha
                hide-mode-switch
            />
        </v-col>
    </v-row>
    <hr />
    <v-row>
        <v-col>
            <div class="line-item--label">
                Tile image
            </div>
        </v-col>
        <v-col>
            <v-select
                :items="tileImageTypes"
                v-model="value.tileImage.type"
                dense
                required
                attach
            />
        </v-col>
    </v-row>
    <v-row v-if="value.tileImage.type === 'file'">
        <v-col>
            <div class="line-item--label">
                Upload image file
            </div>
        </v-col>
        <v-col>
            <v-file-input
                v-model="value.tileImage.file"
                label="Upload"
                clearable
                dense
            />
        </v-col>
    </v-row>
    <hr>
    <v-row>
        <v-col>
            <div class="line-item--label">
                Tile overlay color (optional)
            </div>
        </v-col>
        <v-col>
            <v-select
                :items="tileOverlayColorTypes"
                v-model="value.overlayColor.type"
                dense
                required
                attach
            />
        </v-col>
    </v-row>
    <v-row v-if="value.overlayColor.type === 'color'">
        <v-col></v-col>
        <v-col>
            <v-color-input
                v-model="value.overlayColor.color"
                :label="value.overlayColor.color"
                mode="hex"
                elevation="2"
                class="color-input"
                no-alpha
                hide-mode-switch
            />
        </v-col>
    </v-row>
    <hr>
    <v-row>
        <v-col>
            <div class="line-item--label">
                Page link (optional)
            </div>
        </v-col>
        <v-col>
            <v-text-field
                label="Page link (optional)"
                v-model="value.pageLink"
                dense
            />
        </v-col>
    </v-row>
</div>
</template>

<script>
import TextFieldRow from "components/create_store/fields/TextFieldRow";
import ColorInputRow from "components/create_store/fields/ColorInputRow";

export default {
    name: "SecondaryTileOptionsEditor",
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    components: {
        TextFieldRow,
        ColorInputRow,
    },
    data() {
        return {
            tileTypeOptions: [
                {text: 'Tab', value: 'tab'},
                {text: 'Survey', value: 'survey'},
                {text: 'Nutritional Information', value: 'nutritionalInformation'},
                {text: 'About', value: 'aboutUs'},
                {text: 'Caloric Information', value: 'caloricInformation'},
            ],
            iconTypes: [
                {text: 'Use Default', value: 'default'},
                {text: 'Custom Color', value: 'color'},
                {text: 'Use Custom Image', value: 'file'},
            ],
            tileImageTypes: [
                {text: 'Use Default', value: 'default'},
                {text: 'No image', value: 'color'},
                {text: 'Use Custom Image', value: 'file'},
            ],
            tileOverlayColorTypes: [
                {text: 'No Color', value: 'noColor'},
                {text: 'Custom Color', value: 'color'},
            ],
        }
    },
}
</script>

<style scoped lang="scss">
@import "/src/pages/create_store/wizard/section";
</style>
