<template>
    <div>
        <color-input-row
            v-model="value.backgroundColor"
            line-number="1"
            title="Background color"
        />
        <v-divider />
        <color-input-row
            v-model="value.spinner"
            line-number="2"
            title="Spinner"
        />
        <v-divider />
        <color-input-row
            v-model="value.languageIcon"
            line-number="3"
            title="Language icon"
        />
        <v-divider />
        <file-input-row
            v-model="value.siteLogo"
            line-number="5"
            title="Logo"
            input-label="Logo (.png or .svg preferred)"
            required
        />
        <v-divider />
        <field-row line-number="6" title="Primary tile" />
        <v-divider class="ml-16"/>
        <select-row
            v-model="value.primaryTile.type"
            :items="tileTypeOptions"
            title="Type"
        />
        <v-divider class="ml-16"/>
        <text-field-row
            v-model="value.primaryTile.label"
            title="Label"
        />
        <color-input-row
            v-model="value.primaryTile.labelColor"
            title="Label Color"
        />
        <v-divider class="ml-16" />
        <select-row
            :items="iconTypes"
            v-model="value.primaryTile.icon.type"
            title="Icon"
        />
        <color-input-row
            v-if="value.primaryTile.icon.type === 'color'"
            v-model="value.primaryTile.icon.color"
            title="Color"
         />
        <file-input-row
            v-if="value.primaryTile.icon.type === 'file'"
            v-model="value.primaryTile.icon.file"
            title="File"
            input-label="Upload"
            required
        />
        <v-divider class="ml-16" />
        <color-input-row
            v-model="value.primaryTile.color"
            title="Tile color"
        />
        <select-row
            :items="tileImageTypes"
            v-model="value.primaryTile.tileImage.type"
            title="Tile image"
        />
        <file-input-row
            v-if="value.primaryTile.tileImage.type === 'file'"
            v-model="value.primaryTile.tileImage.file"
            title="Upload image file"
        />
        <v-divider class="ml-16" />
        <select-row
            v-model="value.primaryTile.overlayColor.type"
            :items="tileOverlayColorTypes"
            title="Tile overlay color (optional)"
        />
        <color-input-row
            v-if="value.primaryTile.overlayColor.type === 'color'"
            v-model="value.primaryTile.overlayColor.color"
            title="Overlay Color"
        />
        <v-divider />
        <tile-editor
            v-for="(tile,i) of value.secondaryTiles"
            :value="tile"
            :base-index="7"
            :index="i"
            :is-last-tile="i === value.secondaryTiles.length - 1"
            @discard="discardTile(i)"
            @reorderUpward="reorderTileUpward(i)"
            @reorderDownward="reorderTileDownward(i)"
        />
        <v-row>
            <v-col class="text-center">
                <v-btn @click="addSecondaryTile()">
                    Add a secondary tile
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import LineNumber from "components/LineNumber";
import TextTooltip from "components/text_tooltip";
import TileEditor from "components/create_store/tile_editor";
import TextFieldRow from "components/create_store/fields/TextFieldRow";
import FileInputRow from "components/create_store/fields/FileInputRow";
import ColorInputRow from "components/create_store/fields/ColorInputRow";
import FieldRow from "components/create_store/fields/FieldRow";
import SelectRow from "components/create_store/fields/SelectRow";

export default {
    name: "LaunchScreenTiled",
    components: {
        LineNumber,
        TextTooltip,
        TileEditor,
        TextFieldRow,
        FileInputRow,
        ColorInputRow,
        FieldRow,
        SelectRow,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        lineNumberProps: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            tileTypeOptions: [
                {text: 'Main Menu', value: 'mainMenu'},
                {text: 'Tab', value: 'tab'},
                {text: 'Survey', value: 'survey'},
                {text: 'Nutritional Information', value: 'nutritionalInformation'},
                {text: 'About', value: 'aboutUs'},
            ],
            iconTypes: [
                {text: 'Use Default', value: 'default'},
                {text: 'Custom Color', value: 'color'},
                {text: 'Use Custom Image', value: 'file'},
            ],
            tileImageTypes: [
                {text: 'Use Default', value: 'default'},
                {text: 'No image', value: 'color'},
                {text: 'Use Custom Image', value: 'file'},
            ],
            tileOverlayColorTypes: [
                {text: 'No Color', value: 'noColor'},
                {text: 'Custom Color', value: 'color'},
            ],
        };
    },
    methods: {
        addSecondaryTile() {
            this.$emit('onAddTile');
        },
        discardTile(i) {
            this.$emit('onDiscardTile', i);
        },
        reorderTileUpward(i) {
            this.$emit('onReorderTileUpward', i);
        },
        reorderTileDownward(i) {
            this.$emit('onReorderTileDownward', i);
        }
    }
}
</script>

<style scoped lang="scss">
@import "/src/pages/create_store/wizard/section";
</style>
