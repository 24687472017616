var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("div", { staticClass: "line-item--label" }, [
              _vm._v(" Survey Icon "),
            ]),
          ]),
          _c(
            "v-col",
            [
              _c(
                "v-radio-group",
                {
                  attrs: { row: "", dense: "" },
                  model: {
                    value: _vm.value.survey.iconType,
                    callback: function ($$v) {
                      _vm.$set(_vm.value.survey, "iconType", $$v)
                    },
                    expression: "value.survey.iconType",
                  },
                },
                [
                  _c(
                    "v-radio",
                    { attrs: { value: "stars" } },
                    [
                      _c(
                        "template",
                        { slot: "label" },
                        [_vm._v(" Stars "), _c("v-icon", [_vm._v("star")])],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "v-radio",
                    { attrs: { value: "smiles" } },
                    [
                      _c(
                        "template",
                        { slot: "label" },
                        [_vm._v(" Smiles "), _c("v-icon", [_vm._v("face")])],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("hr"),
      _vm._l(_vm.value.survey.questions, function (_, i) {
        return _c(
          "v-row",
          [
            _c("v-col", [
              _c("div", { staticClass: "line-item--label" }, [
                _vm._v(" Question #" + _vm._s(i + 1) + " "),
              ]),
            ]),
            _c(
              "v-col",
              [
                _c("v-text-field", {
                  attrs: { label: "Question #" + (i + 1), dense: "" },
                  model: {
                    value: _vm.value.survey.questions[i],
                    callback: function ($$v) {
                      _vm.$set(_vm.value.survey.questions, i, $$v)
                    },
                    expression: "value.survey.questions[i]",
                  },
                }),
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { md: "1" } },
              [
                i !== 0
                  ? _c(
                      "v-btn",
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteQuestion(i)
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("delete")])],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-btn",
                {
                  attrs: { outlined: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.addQuestion()
                    },
                  },
                },
                [_vm._v(" Add another question ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }