var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "field-row",
    {
      attrs: {
        title: _vm.title,
        tooltip: _vm.tooltip,
        "line-number": _vm.lineNumber,
      },
    },
    [
      _c("v-file-input", {
        attrs: {
          label: _vm.inputLabel || _vm.title,
          clearable: "",
          dense: "",
          required: _vm.required,
          rules: [(v) => !!v || "Field is required!"],
          value: _vm.value,
        },
        on: {
          change: function ($event) {
            return _vm.$emit("input", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }