var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("div", { staticClass: "line-item--label" }, [_vm._v(" Label ")]),
          ]),
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: { label: "Label", dense: "" },
                model: {
                  value: _vm.value.label,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "label", $$v)
                  },
                  expression: "value.label",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("div", { staticClass: "line-item--label" }, [
              _vm._v(" Label Color "),
            ]),
          ]),
          _c(
            "v-col",
            [
              _c("v-color-input", {
                staticClass: "color-input",
                attrs: {
                  label: _vm.value.labelColor,
                  mode: "hex",
                  elevation: "2",
                  "no-alpha": "",
                  "hide-mode-switch": "",
                },
                model: {
                  value: _vm.value.labelColor,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "labelColor", $$v)
                  },
                  expression: "value.labelColor",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("div", { staticClass: "line-item--label" }, [_vm._v(" Type ")]),
          ]),
          _c(
            "v-col",
            [
              _c("v-select", {
                attrs: {
                  items: _vm.tileTypeOptions,
                  dense: "",
                  required: "",
                  attach: "",
                },
                model: {
                  value: _vm.value.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "type", $$v)
                  },
                  expression: "value.type",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("div", { staticClass: "line-item--label" }, [_vm._v(" Icon ")]),
          ]),
          _c(
            "v-col",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.iconTypes,
                          dense: "",
                          required: "",
                          attach: "",
                        },
                        model: {
                          value: _vm.value.icon.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.value.icon, "type", $$v)
                          },
                          expression: "value.icon.type",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.value.icon.type === "color"
        ? _c(
            "v-row",
            [
              _c("v-col", [
                _c("div", { staticClass: "line-item--label" }, [
                  _vm._v(" Color "),
                ]),
              ]),
              _c(
                "v-col",
                [
                  _c("v-color-input", {
                    staticClass: "color-input",
                    attrs: {
                      label: _vm.value.icon.color,
                      mode: "hex",
                      elevation: "2",
                      "no-alpha": "",
                      "hide-mode-switch": "",
                    },
                    model: {
                      value: _vm.value.icon.color,
                      callback: function ($$v) {
                        _vm.$set(_vm.value.icon, "color", $$v)
                      },
                      expression: "value.icon.color",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.value.icon.type === "file"
        ? _c(
            "v-row",
            [
              _c("v-col", [
                _c("div", { staticClass: "line-item--label" }, [
                  _vm._v(" File "),
                ]),
              ]),
              _c(
                "v-col",
                [
                  _c("v-file-input", {
                    attrs: {
                      label: "Upload",
                      clearable: "",
                      dense: "",
                      required: "",
                      rules: [(v) => !!v || "File is required!"],
                    },
                    model: {
                      value: _vm.value.icon.file,
                      callback: function ($$v) {
                        _vm.$set(_vm.value.icon, "file", $$v)
                      },
                      expression: "value.icon.file",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("hr"),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("div", { staticClass: "line-item--label" }, [
              _vm._v(" Tile color "),
            ]),
          ]),
          _c(
            "v-col",
            [
              _c("v-color-input", {
                staticClass: "color-input",
                attrs: {
                  label: _vm.value.color,
                  mode: "hex",
                  elevation: "2",
                  "no-alpha": "",
                  "hide-mode-switch": "",
                },
                model: {
                  value: _vm.value.color,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "color", $$v)
                  },
                  expression: "value.color",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("hr"),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("div", { staticClass: "line-item--label" }, [
              _vm._v(" Tile image "),
            ]),
          ]),
          _c(
            "v-col",
            [
              _c("v-select", {
                attrs: {
                  items: _vm.tileImageTypes,
                  dense: "",
                  required: "",
                  attach: "",
                },
                model: {
                  value: _vm.value.tileImage.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.value.tileImage, "type", $$v)
                  },
                  expression: "value.tileImage.type",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.value.tileImage.type === "file"
        ? _c(
            "v-row",
            [
              _c("v-col", [
                _c("div", { staticClass: "line-item--label" }, [
                  _vm._v(" Upload image file "),
                ]),
              ]),
              _c(
                "v-col",
                [
                  _c("v-file-input", {
                    attrs: { label: "Upload", clearable: "", dense: "" },
                    model: {
                      value: _vm.value.tileImage.file,
                      callback: function ($$v) {
                        _vm.$set(_vm.value.tileImage, "file", $$v)
                      },
                      expression: "value.tileImage.file",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("hr"),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("div", { staticClass: "line-item--label" }, [
              _vm._v(" Tile overlay color (optional) "),
            ]),
          ]),
          _c(
            "v-col",
            [
              _c("v-select", {
                attrs: {
                  items: _vm.tileOverlayColorTypes,
                  dense: "",
                  required: "",
                  attach: "",
                },
                model: {
                  value: _vm.value.overlayColor.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.value.overlayColor, "type", $$v)
                  },
                  expression: "value.overlayColor.type",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.value.overlayColor.type === "color"
        ? _c(
            "v-row",
            [
              _c("v-col"),
              _c(
                "v-col",
                [
                  _c("v-color-input", {
                    staticClass: "color-input",
                    attrs: {
                      label: _vm.value.overlayColor.color,
                      mode: "hex",
                      elevation: "2",
                      "no-alpha": "",
                      "hide-mode-switch": "",
                    },
                    model: {
                      value: _vm.value.overlayColor.color,
                      callback: function ($$v) {
                        _vm.$set(_vm.value.overlayColor, "color", $$v)
                      },
                      expression: "value.overlayColor.color",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("hr"),
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("div", { staticClass: "line-item--label" }, [
              _vm._v(" Page link (optional) "),
            ]),
          ]),
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: { label: "Page link (optional)", dense: "" },
                model: {
                  value: _vm.value.pageLink,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "pageLink", $$v)
                  },
                  expression: "value.pageLink",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }