<template>
    <v-row>
        <v-col>
            <div class="line-item--label">
                Caloric information
            </div>
        </v-col>
        <v-col>
            <v-textarea
                v-model="value.caloricInformation"
                label="Caloric information"
                dense
            />
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "CaloricInformationEditor",
    props: {
        value: {
            type: Object,
            required: true,
        }
    },
}
</script>

<style scoped lang="scss">
@import "/src/pages/create_store/wizard/section";
</style>
